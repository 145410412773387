import request from '@/utils/request'


// 查询用户学历认证列表
export function listEducationAuth(query) {
  return request({
    url: '/user/user-education-auths/list',
    method: 'get',
    params: query
  })
}

// 查询用户学历认证分页
export function pageEducationAuth(query) {
  return request({
    url: '/user/user-education-auths/page',
    method: 'get',
    params: query
  })
}

// 查询用户学历认证详细
export function getEducationAuth(data) {
  return request({
    url: '/user/user-education-auths/detail',
    method: 'get',
    params: data
  })
}

// 新增用户学历认证
export function addEducationAuth(data) {
  return request({
    url: '/user/user-education-auths/add',
    method: 'post',
    data: data
  })
}

// 修改用户学历认证
export function updateEducationAuth(data) {
  return request({
    url: '/user/user-education-auths/edit',
    method: 'post',
    data: data
  })
}

// 删除用户学历认证
export function delEducationAuth(data) {
  return request({
    url: '/user/user-education-auths/delete',
    method: 'post',
    data: data
  })
}
export function batchApprove(data) {
  return request({
    url: '/user/user-education-auths/batchApprove',
    method: 'post',
    data: data
  })
}

